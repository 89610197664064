.loading-container-wrapper {
    background-color: #000;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9998;
    left: 0;
    top: 0;
    opacity: 0.4;
}

.inline-cover {
    position: absolute !important;
}

.inline-cover img {
    max-height: 100px;
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9999;
    color: white;
}

.inline-loading-container-wrapper img {
    height: 200px;
}

.dot {
    width: 10px;
    height: 10px;
    border: 2px solid white;
    border-radius: 50%;
    float: left;
    margin: 0 5px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: fx 1000ms ease infinite 0ms;
    animation: fx 1000ms ease infinite 0ms;
}

.dot:nth-child(2) {
    -webkit-animation: fx 1000ms ease infinite 300ms;
    animation: fx 1000ms ease infinite 300ms;
}

.dot:nth-child(3) {
    -webkit-animation: fx 1000ms ease infinite 600ms;
    animation: fx 1000ms ease infinite 600ms;
}

@-webkit-keyframes fx {
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fx {
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
