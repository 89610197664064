@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,400;1,500;1,600&family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500&display=swap');

body {
    background-color: #f3f3f3 !important;
    color: #3c4858;
    font-size: 12.5px;
}

button {
    outline: none !important;
}

.site-content {
    padding-top: 15px;
}

/* scrollbar */

/* Hide scrollbar for Chrome, Safari and Opera */
[role="tablist"]::-webkit-scrollbar {
    display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
[role="tablist"] {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


/*-------Updated toast CSS as per bootstrap-------*/
.toast {
    font-size: 1.125rem !important;
    z-index: 99999 !important;
}

.toast-success {
    background-color: #b4e0b5 !important;
    color: #306e33 !important;
}

.toast-success button {
    color: #306e33 !important;
}

.toast-info {
    color: #24747d !important;
    background-color: #d1ecf1 !important;
}

.toast-info button {
    color: #24747d !important;
}

.toast-error {
    color: #721c24 !important;
    background-color: #f8d7da !important;
}

.required label {
    color: red !important;
}

.toast-error button {
    color: #721c24 !important;
}

/*-------Updated toast CSS as per bootstrap-------*/

.text-transform-none {
    text-transform: none;
}

.opacity-none {
    opacity: 1 !important;
}

/*-------Sticky Footer bootstrap-------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%; /* 1, 3 */
}

.site-header,
.site-footer {
    flex: none; /* 2 */
}

.site-content {
    flex: 1 0 auto; /* 2 */
    width: 100%;
}

.site-content::after {
    content: "\00a0"; /* &nbsp; */
    display: block;
    margin-top: 1.5em;
    height: 0;
    visibility: hidden;
}

@media (min-width: 768px) {
    .site-content::after {
        margin-top: 1.5em;
    }
}

.site-content--full {
    padding: 0;
}

.site-content--full::after {
    content: none;
}

.infinite-scroll-card {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;
}

.text-wrap {
    word-wrap: break-word;
}

.italic {
  font-style: italic;
}

.cursor-pointer {
    cursor: pointer;
}

.button-link:hover {
    color: white;
}

a:hover {
    color: black;
}

.hidden {
    display: none;
}
.fixBottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px !important;
    width: 55px;
    margin: 0 auto !important;
}

@media (max-width: 991px) {
    #staff-filter {
        margin: 1rem 0;
    }
}

/**Added to remove safari human icon**/
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
/**Added to remove safari human icon**/

/**Added to remove scrollbar in edge**/

@supports (-ms-ime-align: auto) {
    .text-wrap {
        word-wrap: break-word;
        word-break: break-all;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    html,
    body {
        -ms-overflow-style: none;
        overflow: auto;
    }

    input::-ms-clear {
        display: none;
    }
}
/**Added to remove scrollbar in edge**/

/* List Action Styling */

.action-grid {
    align-items: center;
    z-index: 2;
}
.action-btn.disabled {
    opacity: 0.6;
}
.action-btn.disabled:hover {
    background-color: lightgray !important;
}
.sigCanvas {
  border: solid 2px rgba(0,0,0,0.54);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .sigCanvas {
    width: 225px !important;
    height: 125px;
  }
}

@media (min-width: 991px) {
   
    .action-btn {
        background-color: lightgray !important;
        border-radius: 5px !important;
        float: right;
        width: 100px !important;
        height: auto !important;
        margin: 3px !important;
        max-height: 50px;
        max-width: 100%;
        padding: 5px !important;
    }
    .action-btn:hover {
        border-radius: 5px;
        background-color: #939598 !important;
        text-decoration: none;
    }
    .checkbox-text {
        display: inline-block !important;
        width: 95%;
        padding-left: 5px;
    }
    .schedule-shift-checkbox {
        float: left; 
        width: 5% !important;
    }
}

@media (max-width: 768px) {

    .checkbox-container {
        margin-top: 15px;
        position: relative;
        right: -10px;
    }
}




